import { isAdmin, isUnderwriter } from '@/components/routing/roles';
import { renderFleetAndVesselSearchResult } from '@/components/shared/MarineObjectSearch';
import { SearchFieldDropdown } from '@/components/shared/Form/FormFields';
import { DataRowWithLoading, } from '@/components/shared/Table/core/Components';
import useDebounce from '@/hooks/useDebouncedValue';
import { Filters } from '@/models/Filters';
import { useFleetsAndVessels } from '@/services/fleetsService';
import { useVessels } from '@/services/vesselsService';
import { WarWeb } from '@/war';
import {
  ChangeEvent, useMemo, useState
} from 'react';
import styled from 'styled-components';
import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { useAgreementSelectionContext } from '@/components/pages/agreementDetails/AgreementSelectionContext';
import { useIsBulkEditing } from '@/components/pages/agreements/useIsBulkEditing';
import { PaginationBar } from '@/components/pages/common/PaginationBar';
import { useFilters } from './useFilters';
import { VesselTable } from './VesselTable';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.3rem;
  width: 35vw;

  div {
    width: 100%;
  }
`;

export const VesselSelector = () => {
  const { viewMode, targetsSummary } = useAgreementDetailsContext();
  const { fleetIds, setFleetIds } = useAgreementSelectionContext();

  const isBulkEdit = useIsBulkEditing();
  const canEditVessel = viewMode !== AgreementViewMode.Inspect && !isBulkEdit;

  const [searchFleetsFilters, setSearchFleetsFilters] = useState<Filters>();
  const debouncedFleetsFilters = useDebounce(searchFleetsFilters, 500);
  const { data: results, isLoading: searchLoading } = useFleetsAndVessels(debouncedFleetsFilters);

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchFleetsFilters({ ...searchFleetsFilters, freetext: e.target.value });
  };

  const originalVesselIds = useMemo(() => targetsSummary?.vessels.map(x => x.vesselId) || [], [targetsSummary.vessels]);

  const { filters, setFilters, initialFilters } = useFilters(canEditVessel, originalVesselIds);
  const debouncedFilters = useDebounce(filters, 500);
  const { data: vessels, isLoading, error } = useVessels(isAdmin() || debouncedFilters.fleetId ? debouncedFilters : undefined);

  const setFleet = (selection: WarWeb.MarineMatch) => {
    setFleetIds([selection.fleetId]);
    setFilters({ ...filters, fleetId: `${selection.fleetId}` });
    setSearchFleetsFilters(undefined);
  };

  return (
    <>
      {isAdmin() || fleetIds.length > 0 ? (
        <DataRowWithLoading hasData={!!vessels} isLoading={isLoading} hasError={!!error}>
          <VesselTable
            vessels={vessels!}
            filters={filters}
            setFilters={setFilters}
            canEditVessel={canEditVessel}
            initialFilters={initialFilters}
            originalVesselIds={originalVesselIds}
          />
        </DataRowWithLoading>
      ) : (
        isUnderwriter() && (
          <Content>
            <SearchFieldDropdown
              label="Search"
              name="area"
              placeholder="Fleet name, Vessel name, IMO Number"
              onChange={handleInput}
              results={results}
              keyField="vesselId"
              loadStatus={{ loading: searchLoading, success: !!results }}
              callback={setFleet}
              renderResult={renderFleetAndVesselSearchResult}
            />
          </Content>
        )
      )}
      {vessels?.pagingMetadata && (
        <PaginationBar
          currentPage={+filters.pageNumber}
          metadata={vessels.pagingMetadata}
          changePage={newPage => setFilters({ ...filters, pageNumber: `${newPage}` })}
        />
      )}
    </>
  );
};
