import { Load, WarningFilled } from '@instech/icons';
import React, { PropsWithChildren, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';
import { theme } from '@/utility/theme';
import { isNumber } from '@/utility/formatter';
import { DataType } from '@/models/Datatype';
import { LinkButton } from '../../../shared/LinkButton/LinkButton';
import { NoData } from '../../NoData';
import { ErrorMessage } from '../../Form/core/Components';

const ButtonWrapper = styled.div<{ lineLeft?: boolean }>`
  display: flex;
  align-items: center;

  ${props => props.lineLeft && css`
    border-left: 1px solid;
    border-left-color: ${props.theme.lightGray};
  `};
`;

interface CellButtonProps {
  lineLeft?: boolean;
  icon: any;
  onClick?: () => any;
}
export const CellButton = ({ lineLeft, icon, onClick }: CellButtonProps) => (
  <ButtonWrapper lineLeft={lineLeft}>
    <LinkButton
      startIcon={icon}
      onClick={onClick}
      width="40px"
      height="40px"
    />
  </ButtonWrapper>
);

export const BorderedTextBox = styled.div<{ onClick?: () => void }>`
  display: flex;
  align-items: center;

  border: 1px solid ${props => props.theme.lightGreen80};
  border-radius: 3px;
  width: fit-content;
  
  background: ${props => props.theme.lightGreen10};
  color: ${props => props.theme.mediumGreen};
  font-size: 12px;
  font-weight: bold;
  padding: 0 .5rem;
  line-height: 16px;
  
  ${props => props.onClick && css`
    cursor: pointer;
    padding-right: 0;
  `};
`;

interface TableCellProps {
  span?: string | number;
  disabled?: boolean;
  right?: boolean;
  center?: boolean;
  top?: boolean;
  delimiter?: string;
  slim?: boolean;
  height?: string;
  fit?: boolean;
  hover?: boolean;
  textWrap?: boolean;
  backgroundColor?: string;
  minWidth?: string;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}
export const TableCell = styled.span<TableCellProps>`
  ${props => !props.height && css`
    min-height: 44px; // The design shows both 40 and 48px cells, for now we use one size for all
  `};   // except! when we need to set specific heights...
  display: flex;
  ${props => props.minWidth && css`
    min-width: ${props.minWidth};
  `}
  height: 100%;
  flex-direction: ${props => props.center ? 'row' : 'column'};
  justify-content: ${props => props.top ? 'top' : 'center'};
  font-size: 14px;

  & input {
    font-size: 16px;
  }

  ${props => props.span && css`
    grid-column: ${isNumber(`${props.span}`) ? `span ${props.span}` : `${props.span}`};
  `};
  padding: ${props => props.slim ? 0 : '10px'};  
  box-sizing: border-box;
  white-space: ${props => !props.textWrap && 'nowrap'};
  overflow: ${props => props.fit ? 'wrap' : 'hidden'};
  text-overflow: ellipsis;
  ${props => props.hover && css`
    &:hover {
      background-color: ${props.theme.whiteBlue} !important;
      cursor: pointer;
    }
  `};
  ${props => props.onClick && css`
    cursor: pointer;
  `};
  ${props => props.right && css`
    align-items: flex-end;
    padding-right: 2em;
  `};
  ${props => props.center && css`
    align-items: center;
  `};
  ${props => props.delimiter && css`
    border-${props.delimiter}: 1px solid ${props.theme.marineBlue50};
  `}
  ${props => props.backgroundColor && css`
    background-color: ${props.backgroundColor} !important;
  `}

`;

interface TableRowStylingProps {
  backgroundColor?: string;
  shaded?: boolean;
  hover?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  outline?: boolean;
  hasFocus?: boolean;
  top?: boolean;
  dashed?: boolean;
  transparent?: boolean;
}
export const TableRowStyling = styled.div<PropsWithChildren<TableRowStylingProps>>`
  display: contents;
  ${props => props.backgroundColor && !props.transparent && css`
    // Duplicated to increase specificity
    > ${TableCell}${TableCell}${TableCell} { 
      background-color: ${props.backgroundColor};
    }
  `}

  ${props => props.top && css`
    // Duplicated to increase specificity
    > ${TableCell}${TableCell}${TableCell} { 
      justify-content: flex-start;
    }
  `}

  ${props => props.hasFocus && css`
    // Duplicated to increase specificity
    > ${TableCell}${TableCell}${TableCell} { 
      background-color: ${theme.whiteBlue} !important;
    }
  `}

  ${props => props.outline && css`
    // Duplicated to increase specificity
    > ${TableCell}${TableCell}${TableCell} { 
      border-top: 2px solid ${theme.status.red};
      border-bottom: 2px solid ${theme.status.red};
    }
    > ${TableCell}${TableCell}${TableCell}:first-child { 
      border-left: 2px solid ${theme.status.red};
    }
    > ${TableCell}${TableCell}${TableCell}:last-child { 
      border-right: 2px solid ${theme.status.red};
    }
  `};

  ${props => props.dashed && css`
    // Duplicated to increase specificity
    > ${TableCell}${TableCell}${TableCell} { 
      border-top: 1px dashed ${theme.status.green};
      border-bottom: 1px dashed ${theme.status.green};
      background-color: ${theme.lightGreen10};
    }
    > ${TableCell}${TableCell}${TableCell}:first-child { 
      border-left: 1px dashed ${theme.status.green};
    }
    > ${TableCell}${TableCell}${TableCell}:last-child { 
      border-right: 1px dashed ${theme.status.green};
    }
  `};

  cursor: ${props => props.onClick && 'pointer'};
`;

interface TableRowProps extends TableRowStylingProps {
  onHover?: () => void;
}

export const TableRow = ({ children, onHover, ...props }: PropsWithChildren<TableRowProps>) => {
  const [hover, setHover] = useState(false);
  if (!children) return null;

  const enhanceChildren = (elements: any): React.ReactChild => React.Children.map(elements, (child: any) => {
    if (!child) return null;
    if (child.type === React.Fragment) return enhanceChildren(child.props.children);
    return React.cloneElement(
      child,
      { backgroundColor: hover ? theme.whiteBlue : undefined }
    );
  });

  return (
    <TableRowStyling
      onMouseEnter={() => {
        setHover(!!props.hover);
        if (onHover) onHover();
      }}
      onMouseLeave={() => setHover(false)}
      {...props}>
      {enhanceChildren(children)}
    </TableRowStyling>
  );
};

interface EditableTableRowProps {
  isEditMode: boolean;
  even?: boolean;
}
export const EditableTableRow = styled.span<EditableTableRowProps>`
  display: contents;
  ${TableCell} {
    ${props => props.isEditMode ? `
      background-color: ${props.theme.whiteBlue};
      border-top: 1px solid ${props.theme.border.gray};
      border-bottom: 1px solid ${props.theme.border.gray};
    ` : `
      background-color: ${props.even ? props.theme.white : props.theme.flatWhite};
    `}}
`;

export const ExpandedDetailRow = styled(TableRow) <{ isOpen: boolean }>`
  display: ${props => props.isOpen ? 'contents' : 'none'};
  & ${TableCell} {
    justify-content: flex-start;
    background: ${props => props.theme.whiteBlue};    
  }
`;

interface TableHeaderProps {
  delimiter?: string;
  span?: string | number;
  noBorder?: boolean;
  right?: boolean;
  center?: boolean;
  fit?: boolean;
}
export const TableHeader = styled.span<TableHeaderProps>`
  padding: 10px 14px 10px 10px;
  background-color: ${props => props.theme.white};
  font-size: 14px;
  font-weight: bold;
  ${props => !props.noBorder && css`
    border-bottom: 1px solid ${props.theme.marineBlue50};
  `};
  display: flex;
  
  ${props => props.right && css`
    justify-content: flex-end;
    text-align: right;
  `};

  ${props => props.center && css`
    justify-content: center;
  `};

  ${props => props.span && css`
    grid-column: span ${props.span};
  `}

  ${props => props.delimiter && css`
    border-${props.delimiter}: 1px solid ${props.theme.marineBlue50};
  `}

  ${props => props.fit !== null && css`
    overflow: ${props.fit ? 'wrap' : 'hidden'};
  `};

  h2 {
    margin: 0;
  }
`;

interface TableHeaderCellSpanProps {
  span: number | string;
}
export const TableHeaderCellSpan = styled(TableCell) <TableHeaderCellSpanProps>`
  font-size: 18px;
  font-weight: bold;
  justify-content: flex-start;
`;

export const renderTableHeaders = (
  headers: Array<string>,
  delimiters: number[] = [],
  rightAlignedColumns: number[] = [],
  centerAlignedColumns: number[] = [],
  noBorder: boolean = false
) =>
  headers.map((header, i) => (
    <TableHeader
      key={header}
      delimiter={delimiters.includes(i) ? 'left' : undefined}
      right={rightAlignedColumns.includes(i) ? true : undefined}
      center={centerAlignedColumns.includes(i) ? true : undefined}
      noBorder={noBorder}>
      {header}
    </TableHeader>
  ));

export interface TableColumnHeader {
  title?: string;
  subtitle?: string;
  key?: number;
  format?: string;
  type?: DataType;
  propertyName?: string;
  parameterName?: string;
  noDropdown?: boolean;
  tag?: string;
  align?: string;
  dropdownAlign?: string;
}

export interface TableSection {
  name?: string;
  subheader?: string;
  columns: TableColumnHeader[];
  tag?: string;
}

export const getHeaderAlignment = (header?: TableColumnHeader) => {
  if (!header) return '';
  if (header.align) return header.align;
  return ['int', 'wide-int'].includes(header?.format || '') ? 'right' : '';
};

export const getColumnWidth = (format?: string) => {
  switch (format) {
    case 'char': return '0.2fr';
    case 'text': return '1fr';
    case 'label': return '0.6fr';
    case 'int': return '0.5fr';
    case 'wide-int': return '0.5fr';
    case 'decimal': return '0.5fr';
    default:
      return '0.35fr';
  }
};

export const getDelimiters = (tableSections: TableSection[], filter?: (a: TableColumnHeader) => boolean) => {
  const delimiters: number[] = [];
  let i = 0;
  tableSections.forEach(el => {
    const columns = filter ? el.columns.filter(filter) : el.columns;
    if (el.name && i !== 0) delimiters.push(i);
    i += columns.length;
  });
  return delimiters;
};

export const getColspans = (tableSections: TableSection[]) => {
  const colspans: number[] = [];
  tableSections.forEach(el => {
    colspans.push(el.columns.length);
  });
  return colspans;
};

export const getHeaders = (tableSections: TableSection[], filter?: (a: TableColumnHeader) => boolean) => {
  const header = tableSections.flatMap(x => x.columns);
  if (filter) return header.filter(filter);
  return header;
};

const SubHeader = styled.div`
  font-weight: normal;
  font-size: 16px;
`;
export const renderTableSectionHeaders = (tableSections: TableSection[], filter?: (a: TableColumnHeader) => boolean) => {
  const sections = tableSections.map(el => {
    const columns = filter ? el.columns.filter(filter) : el.columns;
    let element;
    if (!el.name) {
      element = <TableHeaderCellSpan span={columns.length} />;
    } else {
      element = (
        <TableHeaderCellSpan span={columns.length} delimiter={tableSections.indexOf(el) !== 0 ? 'left' : undefined}>
          {el.name}
          <SubHeader>{el.subheader}</SubHeader>
        </TableHeaderCellSpan>
      );
    }
    return (
      <React.Fragment key={el.name || 'header'}>
        {element}
      </React.Fragment>
    );
  });

  return (
    <>
      {sections}
    </>
  );
};

const IconContainer = styled.div`
  padding-bottom: 1rem;
`;

export const SpanCell = styled(TableCell) <{ start?: number, span?: number, end?: number }>`
  ${props => !props.end && props.span && css`
    grid-column: ${props.start} / span ${props.span};
  `};
  ${props => !props.span && props.end && css`
    grid-column: ${props.start} / ${props.end};
  `};
  padding: 0;
`;

interface ErrorRowProps {
  error?: string;
  start: number;
  span?: number;
  end?: number;
  hasDarkBackground?: boolean;
}
export const ErrorRow = ({ error, hasDarkBackground = false, start, span, end }: ErrorRowProps) => {
  if (!error) return null;

  return (
    <>
      {start > 2 && <SpanCell height="1" start={1} span={start - 1} />}
      <SpanCell height="1" start={start} span={span} end={end}>
        <ErrorMessage align="left" hasDarkBackground={hasDarkBackground} notRounded>
          <div><WarningFilled /></div>
          <div>{error}</div>
        </ErrorMessage>
      </SpanCell>
    </>
  );
};

interface NoDataCellProps {
  size?: string
}
const NoDataCell = styled(TableCell) <NoDataCellProps>`
  display: flex;
  height: ${props => props.size === 'small' ? 200 : 400}px;

  align-items: center;
  justify-content: center;
  grid-column: 1/-1;

  color: ${props => props.theme.marineBlue50};
`;

const LoadIcon = styled(Load)`
  height: 20px;
`;

interface StatusLoading {
  loading: boolean,
  success?: boolean
}
interface NoDataRowProps {
  backgroundColor?: string,
  loadStatus: StatusLoading,
  size?: string,
  header?: string,
  message?: string
}
export const NoDataRow = ({
  backgroundColor = theme.white,
  loadStatus,
  size = 'normal',
  header,
  message
}: NoDataRowProps) => (
  <TableRow backgroundColor={backgroundColor} shaded={false}>
    <NoDataCell size={size}>
      {loadStatus.loading ?
        <IconContainer><LoadIcon /></IconContainer>
        :
        <NoData size={size} success={loadStatus.success} header={header} message={message} />}
    </NoDataCell>
  </TableRow>
);

interface DataRowWithLoadingProps {
  hasData: boolean;
  isLoading: boolean;
  hasError: boolean;
}
export const DataRowWithLoading = ({ hasData, isLoading, hasError, children }: PropsWithChildren<DataRowWithLoadingProps>) =>
  hasData ? <>{children}</> : <NoDataRow loadStatus={{ loading: isLoading, success: !hasError }} />;

const EmptyRow = styled(TableCell)`
  grid-column: 1/-1;
`;

export const EmptyTable = () => (
  <>
    <TableRow>
      <EmptyRow />
    </TableRow>
    <TableRow>
      <EmptyRow />
    </TableRow>
    <TableRow>
      <EmptyRow />
    </TableRow>
    <TableRow>
      <EmptyRow />
    </TableRow>
    <TableRow>
      <EmptyRow />
    </TableRow>
  </>
);

const NameGroupStyled = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;

const Container = styled.div`
  .tooltip {
    max-width: 400px;
    white-space: normal;
  }
`;

export const NameGroup = ({ values }: { values?: Array<string | number | undefined> }) => {
  const randomID = String(Math.random());

  if (!values || values.length < 1) return null;
  if (values.length === 1) return <>{values[0]}</>;

  return (
    <Container>
      <NameGroupStyled data-for={randomID} data-tip={values.map(v => ` ${v}`)}>{`${values[0]} + ${values.length - 1}`}</NameGroupStyled>
      <ReactTooltip id={randomID} backgroundColor={theme.marineBlue} effect="solid" className="tooltip" />
    </Container>
  );
};

interface TooltipProps {
  name?: string;
  id?: string;
  delayShow?: number;
}
export const Tooltip = ({ name, id, delayShow = 0, children }: PropsWithChildren<TooltipProps>) => {
  if (!name) return <>{children}</>;
  return (
    <>
      <span
        data-tip={name}
        data-for={id}>
        {children}
      </span>
      <ReactTooltip id={id} delayShow={delayShow} backgroundColor={theme.marineBlue} effect="solid" className="tooltip" />
    </>
  );
};
