import { Divider } from '@instech/components';
import { RatesTable } from '@/components/shared/TermsDetails/premiumRebates/RatesTable';
import { RebatesTable } from '@/components/shared/TermsDetails/premiumRebates/RebatesTable';
import { Coverage } from './Coverage';
import { SumInsured } from './SumInsured';

export const DaysCoveredTab = () => (
  <>
    <Coverage />
    <Divider />
    <SumInsured />
    <br />
    <RatesTable agreementType="StandardAgreement" />
    <RebatesTable />
  </>
);
