import { Table, TableRow } from '@/components/shared/Table/Table';
import { WarWeb } from '@/war';
import { RichTextView } from '@/components/shared/Form/RichTextForm/RichTextView';
import {
  ConditionsTableCell, DuoTableLayout, TableHeaderTitle
} from '@/components/shared/TermsDetails/SettingsComponents';

export const WarrantiesSubjectivitiesBroker = ({ data }: { data: WarWeb.BrokerAgreementSummary }) => {
  const { warranties, subjectivities } = data;

  return (
    <DuoTableLayout>

      <Table columns={1}>
        <TableHeaderTitle noBorder>Warranties</TableHeaderTitle>
        {warranties.map(warranty => (
          <TableRow key={warranty.text}>
            <ConditionsTableCell>
              <RichTextView text={warranty.text} />
            </ConditionsTableCell>
          </TableRow>
        ))}
      </Table>

      <Table columns={1}>
        <TableHeaderTitle noBorder>Subjectivities</TableHeaderTitle>
        {subjectivities.map(subjectivity => (
          <TableRow key={subjectivity.text}>
            <ConditionsTableCell>
              <RichTextView text={subjectivity.text} />
            </ConditionsTableCell>
          </TableRow>
        ))}
      </Table>
    </DuoTableLayout>
  );
};
