import React from 'react';
import { TextField } from '@/components/shared/Form/FormFields';
import {
  CoverageSummary, isStandardAgreementType, ValueSummary
} from '@/models/Agreement';
import {
  formatInteger, formatRange, getInputValue
} from '@/utility/formatter';
import { Validator } from '@/components/shared/Validator';
import { useAgreementSelectionContext } from '@/components/pages/agreementDetails/AgreementSelectionContext';
import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { useAgreementDataContext } from '@/components/pages/agreementDetails/AgreementDataContext';
import { MaxNumberTimeValue } from '@/utility/globals';
import { CustomSelect, CustomSelectValue } from '@/components/shared/Form/fields/CustomSelect';
import { ActionMeta, SingleValue } from 'react-select';
import { ContractTypes } from '@/utility/contractTypes';
import { FlexBox } from '@/components/shared/FlexBox';
import styled from 'styled-components';
import { isAdmin } from '@/components/routing/roles';
import { EditDaysCoveredGroup } from './EditDaysCoveredGroup';

type FieldNames = 'daysCovered' | 'maximumDaysCovered' | 'quoteValidHours' | 'maximumProRataDays';
type ErrorKeys = 'DaysCovered' | 'MaximumDaysCovered' | 'QuoteValidHours' | 'MaximumProRataDays';

const StyledFlexBox = styled(FlexBox)`
  flex-wrap: wrap;
`;

const TextFieldContainer = styled.div`
  input {
    width: 150px;
  }
`;

const LabelHeader = styled.span`
  font-weight: bold;
  font-size: 13px;
`;

interface ViewFieldProps {
  label: string;
  value: string;
}
const ViewField = ({ label, value }: ViewFieldProps) => (
  <FlexBox flexDirection="column">
    <LabelHeader>{label}</LabelHeader>
    <span>{value}</span>
  </FlexBox>
);

export const Coverage = () => {
  const { coverage, setCoverage } = useAgreementDataContext();
  const { targetType } = useAgreementSelectionContext();
  const { viewMode } = useAgreementDetailsContext();

  if (!coverage) return null;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: FieldNames) => {
    const { name, value } = e.target;
    const tempCoverage: CoverageSummary = { ...coverage };

    const valueAsInt = value ? parseInt(value) : undefined;

    if (fieldName) {
      const field = tempCoverage[fieldName];
      field.value = valueAsInt;
      field.isDirty = true;
    } else if (name === 'maximumProRataDays') {
      const field = tempCoverage.maximumProRataDays;
      field.value = valueAsInt;
      field.isDirty = true;
    }
    setCoverage(tempCoverage);
  };

  const handleDropdownChange = (input: SingleValue<CustomSelectValue>, action: ActionMeta<CustomSelectValue>) => {
    const tempCoverage: CoverageSummary = { ...coverage };

    const field = tempCoverage.contractType;
    field.value = input?.value === 0 ? 'MinimumProRata' : 'ProRata';
    field.isDirty = true;

    setCoverage(tempCoverage);
  };

  const renderEditField = (item: ValueSummary<number>, fieldName: FieldNames, errorKey: ErrorKeys, label: string) => {
    const value = getInputValue(item.value);
    return (
      <span>
        {viewMode !== AgreementViewMode.Inspect ? (
          <TextFieldContainer>
            <Validator keys={[`Coverage.${errorKey}`]}>
              <TextField type="number"
                label={label}
                maxValue={MaxNumberTimeValue}
                name={item.tier!}
                placeholder={formatRange(item)}
                value={value}
                dirty={item.isDirty}
                numberOfCharacters={5}
                onChange={e => handleInputChange(e, fieldName)} />
            </Validator>
          </TextFieldContainer>
        ) : (
          <ViewField label={label} value={formatRange(item, formatInteger) ?? formatInteger(item.value)} />
        )}
      </span>
    );
  };

  const isOfTypeStandard = isStandardAgreementType(targetType);
  const dropdownOptions = Object.values(ContractTypes).flatMap((c, i) => ({ value: i, label: c }));

  return (
    <StyledFlexBox gap="1rem" justifyContent="space-between">
      <FlexBox gap="2rem">
        {renderEditField(coverage.daysCovered, 'daysCovered', 'DaysCovered', 'Days Covered')}
        {renderEditField(coverage.maximumProRataDays, 'maximumProRataDays', 'MaximumProRataDays', 'Maximum Pro Rata Days')}
        {renderEditField(coverage.quoteValidHours, 'quoteValidHours', 'QuoteValidHours', 'Quote Validity (hours)')}
        {isOfTypeStandard && renderEditField(coverage.maximumDaysCovered, 'maximumDaysCovered', 'MaximumDaysCovered', 'Maximum Days Covered')}
        {
          viewMode === AgreementViewMode.Inspect ? (
            <ViewField label="Contract Type" value={coverage.contractType.value != null ? ContractTypes[coverage.contractType.value] : ''} />
          ) : (
            <CustomSelect
              name="contractType"
              aria-label="Contract Type"
              options={dropdownOptions}
              onChange={handleDropdownChange}
              value={coverage.contractType.value === 'MinimumProRata' ? dropdownOptions[0] : dropdownOptions[1]}
            />
          )
        }

      </FlexBox>
      {isAdmin() && <EditDaysCoveredGroup />}
    </StyledFlexBox>
  );
};
