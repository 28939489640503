import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { useAgreementSelectionContext } from '@/components/pages/agreementDetails/AgreementSelectionContext';
import { useIsBulkEditing } from '@/components/pages/agreements/useIsBulkEditing';
import { CustomSelect, CustomSelectValue } from '@/components/shared/Form/fields/CustomSelect';
import { useVesselTypes } from '@/services/taxonomyService';
import { WarWeb } from '@/war';
import { Remove } from '@instech/icons';
import { useEffect, useState } from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import styled from 'styled-components';
import {
  FilterTargets, FilterValue, SelectionInputContainer
} from './SelectionComponents';

const Container = styled.div`
  display: flex;
`;

export const VesselTypeSelector = () => {
  const { viewMode } = useAgreementDetailsContext();
  const isBulkEdit = useIsBulkEditing();
  const { vesselTypeIds, setVesselTypeIds } = useAgreementSelectionContext();

  const { data: allVesselTypes } = useVesselTypes();

  const [selectedVesselTypes, setSelectedVesselTypes] = useState<WarWeb.VesselTypeSummary[]>([]);
  const canBeEdited = viewMode !== AgreementViewMode.Inspect && !isBulkEdit;

  useEffect(() => {
    if (allVesselTypes) {
      const currentlySelected: WarWeb.VesselTypeSummary[] =
        allVesselTypes?.filter(x => vesselTypeIds.includes(x.id)).map(x => ({ name: x.name, vesselTypeId: x.id }));
      setSelectedVesselTypes(currentlySelected);
    }
  }, [vesselTypeIds, allVesselTypes]);

  const dropdownOptions = allVesselTypes?.map(x => ({ label: x.name, value: x.id }));

  const changeHandler = (input: SingleValue<CustomSelectValue>, action: ActionMeta<CustomSelectValue>) => {
    if (!input) return;
    const item = allVesselTypes?.find(x => x.id === input.value);
    if (!item || vesselTypeIds.includes(item.id)) return;
    setSelectedVesselTypes([...selectedVesselTypes, { name: item.name, vesselTypeId: item.id }]);
    setVesselTypeIds([...vesselTypeIds, item.id]);
  };

  const removeVesselType = (id: number) => {
    setSelectedVesselTypes([...selectedVesselTypes.filter(x => x.vesselTypeId !== id)]);
    setVesselTypeIds([...vesselTypeIds.filter(x => x !== id)]);
  };

  const renderFilterValues = (vesselTypes: WarWeb.VesselTypeSummary[]) => vesselTypes.map(v => (
    <FilterValue key={v.vesselTypeId} vesselType isEdit={canBeEdited} onClick={() => canBeEdited && removeVesselType(v.vesselTypeId)}>
      {v.name}
      {canBeEdited && <Remove />}
    </FilterValue>
  ));

  return (
    <Container>
      {canBeEdited && (
        <SelectionInputContainer>
          <CustomSelect name="vesseltype"
            aria-label="Vessel type"
            onChange={changeHandler}
            placeholder="-Select vessel type-"
            options={dropdownOptions}
            value={null}
          />
        </SelectionInputContainer>
      )}
      <FilterTargets>
        {renderFilterValues(selectedVesselTypes)}
      </FilterTargets>
    </Container>
  );
};
