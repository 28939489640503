import { isUnderwriter } from '@/components/routing/roles';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { TextConditionSummary } from '@/models/Agreement';
import { usePorts } from '@/services/portsService';
import { WarWeb } from '@/war';
import { Approved, MenuDots } from '@instech/icons';
import styled from 'styled-components';
import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { unique } from '../../../../utility/arrayHelpers';
import { MultipleValues } from '../MultipleValues';

const StyledApproved = styled(Approved)`
  path {
    fill: currentColor;
  }
`;

enum TriState {
  'NotSelected', 'Selected', 'Multiple'
}

interface ActionCellProps {
  condition: TextConditionSummary;
  onChange: (checked: boolean, termId: number, tierType: WarWeb.TierType) => void;
}
export const ActionCell = ({ condition, onChange }: ActionCellProps) => {
  const { viewMode, targetsSummary } = useAgreementDetailsContext();

  const tierType = condition.tier!;
  const hasDifferentOptions = condition.includedOptions.some(x => x.option !== condition.includedOptions[0].option);
  const includedOnSomeAgreement = condition.includedOptions.some(x => x.option);

  const relevantOptions = condition.includedOptions.flatMap(x => x.inAgreements);
  const hasNotRelevantAreas = relevantOptions?.some(x => x) && relevantOptions.length < targetsSummary.totalTargetCount;

  const relevantAreas = relevantOptions?.map(u => u?.areaId);
  const notRelevantAreas = {
    inAgreements: targetsSummary.areas.filter(a => !relevantAreas?.includes(a.areaId))?.map(u => ({
      areaId: u.areaId,
      agreementId: null
    }))
  };

  const labels = tierType === 'Minimum' ? ['Mandatory', 'Optional'] : ['Included', 'Not Included'];
  const options =
    [...(tierType === 'Minimum' ? condition.mandatoryOptions : condition.includedOptions),
      ...(hasNotRelevantAreas ? [notRelevantAreas] : [])
    ];
  const portIds = options.map(t => t.inAgreements?.map(v => v.areaId)).flat() || [];
  const { data: ports } = usePorts(portIds ? { selectedAreaIds: portIds.map(p => p || -1) } : undefined);

  const resolveAreaName = (areaId?: number) => {
    if (!areaId) return null;
    return ports?.items.find(p => p.areaId === areaId)?.portName
      || targetsSummary?.areas.find(a => a.areaId === areaId)?.name;
  };

  const resolveLabel = (option?: boolean) => {
    if (option === undefined) return labels[1];
    return option ? labels[0] : labels[1];
  };

  const renderAreaVessels = (o: WarWeb.OptionArea) => (
    unique(o.inAgreements, ['areaId'])
      ?.sort((a, b) => (resolveAreaName(a.areaId) ?? 0) > (resolveAreaName(b.areaId) ?? 0) ? 1 : -1)
      .map((areaVessel: WarWeb.AgreementIdentification) => (
        <div key={areaVessel.areaId}>
          <strong>{resolveAreaName(areaVessel.areaId)}</strong>
          &nbsp;-&nbsp;
          {resolveLabel(o.option)}
        </div>
      )));

  let triState = TriState.NotSelected;
  if ((hasDifferentOptions || (hasNotRelevantAreas && includedOnSomeAgreement)) && !condition.isDirty) triState = TriState.Multiple;
  else triState = condition.includedOptions.some(x => x) && condition.includedOptions[0].option ? TriState.Selected : TriState.NotSelected;

  const shouldShowMultipleValues = triState === TriState.Multiple;

  if (viewMode !== AgreementViewMode.Inspect) {
    if (isUnderwriter()) return <StyledApproved />;
    return (
      <MultipleValues options={triState === TriState.Multiple ? options : []} render={renderAreaVessels}>
        <Checkbox
          name={`${condition.termId}-${tierType}`}
          checked={triState === TriState.Selected || triState === TriState.Multiple}
          onChange={e => onChange(e.target.checked, condition.termId, tierType)}
          indeterminate={shouldShowMultipleValues}
        />
      </MultipleValues>
    );
  }
  if (triState === TriState.Selected) return <StyledApproved />;
  if (triState === TriState.Multiple) {
    return (
      <MultipleValues options={options} render={renderAreaVessels}>
        <MenuDots />
      </MultipleValues>
    );
  }
  return null;
};
