import { openErrorModal } from '@/components/error/ErrorModal';
import { useModalContext } from '@/components/modal/ModalContext';
import { isAdmin, isInternalUser } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { PromptIfDirty } from '@/components/shared/PromptIfDirty';
import { AgreementTypeEnum } from '@/models/Agreement';
import { convertTargetTypeFromAPIToEnum } from '@/models/TargetType';
import { updateAgreements } from '@/services/agreementsService';
import { useValidationContext } from '@/services/ValidationContext';
import { parseErrors } from '@/utility/errorHelpers';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import {
  Add, Approved, Edit, FileParagraph
} from '@instech/icons';
import { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import {
  anyAgreementChanges, getConditionsDelta, getRatesDelta, getRebatesDelta, getCoverageDelta
} from '@/components/shared/TermsDetails/deltaHelper';
import { useAgreementsPageContext } from '../../agreements/AgreementsPageContext';
import { useAgreementSaveTargets } from '../../agreements/useAgreementSaveTargets';
import { ChangeLogModal } from './ChangeLogModal';
import { LastChanged } from './LastChanged';
import { useAgreementDataContext } from '../AgreementDataContext';
import { AgreementViewMode, useAgreementDetailsContext } from '../AgreementDetailsContext';
import { useAgreementSelectionContext } from '../AgreementSelectionContext';
import { SummaryModal } from '../SummaryModal';
import { DeleteSpecificOrTradeAgreementsButton } from './DeleteSpecificAgreementsButton';
import { TradeIndicator } from './TradeIndicator';
import { useCopyModeTargets } from './useCopyModeTargets';
import { AgreementPendingUpdateModal } from './AgreementPendingUpdateModal';
import { DeleteAgreementsButton } from './DeleteAgreementsButton';
import { getRequestHasChanges } from './utils';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 3rem;
`;
const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 1rem;
`;
const DescriptionDate = styled.div`
  font-size: 12px;
  opacity: 0.8;
  letter-spacing: 0.5px;
`;

export const HeaderActions = () => {
  const { agreementType, agreementSummaryTarget, setAgreementSummaryTarget } = useAgreementsPageContext();
  const { vesselIds, areaIds } = useAgreementSelectionContext();
  const { viewMode, setViewMode, mutateAgreement } = useAgreementDetailsContext();
  const { coverage, premium, conditions } = useAgreementDataContext();

  const { setErrors } = useValidationContext();
  const clearErrors = () => setErrors({});
  const history = useHistory();

  const { open } = useModalContext();

  const copyModeTargets = useCopyModeTargets();
  const saveTargets = useAgreementSaveTargets();

  const [saving, setSaving] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [showChangeLogModal, setShowChangeLogModal] = useState(false);
  const [showAgreementPendingModal, setShowAgreementPendingModal] = useState(false);

  const resetForm = () => {
    mutateAgreement();
  };

  const cancelEdit = () => {
    clearErrors();
    resetForm();
    setViewMode(AgreementViewMode.Inspect);
  };

  const onSuccess = (postSaveTargets: WarWeb.AgreementTargets, data?: WarWeb.AgreementUpdateResult) => {
    clearErrors();
    setHasSaved(true);

    if (data?.isQueuedUp) {
      setShowAgreementPendingModal(true);
      setSaving(false);
      return;
    }

    const allAgreements = data?.resultingAgreementIds ?? [];

    if (allAgreements.length === 0) {
      history.push(agreementType === AgreementTypeEnum.Standard ? Routes.termsDefault : Routes.termsSpecific);
      return;
    }

    const targetTypeTemp = convertTargetTypeFromAPIToEnum(data?.resultingTargetType as any);

    setAgreementSummaryTarget({
      agreementIds: allAgreements,
      targetType: targetTypeTemp,
      daysCoveredGroup: postSaveTargets.daysCoveredGroup,
      minimumSumInsured: postSaveTargets.minimumSumInsured,
      hasMultipleSets: postSaveTargets.daysCoveredGroup !== undefined || postSaveTargets.minimumSumInsured !== undefined
    });
    setViewMode(AgreementViewMode.Inspect);
    resetForm();
    setHasSaved(false);
  };

  const onNoChanges = (postSaveTargets: WarWeb.AgreementTargets) => {
    clearErrors();
    setHasSaved(true);

    setAgreementSummaryTarget({
      agreementIds: postSaveTargets.agreementIds ?? [],
      targetType: postSaveTargets.targetType ?? saveTargets.targetType ?? agreementSummaryTarget.targetType,
      daysCoveredGroup: postSaveTargets.daysCoveredGroup,
      minimumSumInsured: postSaveTargets.minimumSumInsured,
      hasMultipleSets: postSaveTargets.daysCoveredGroup !== undefined || postSaveTargets.minimumSumInsured !== undefined
    });

    setViewMode(AgreementViewMode.Inspect);
    resetForm();
    setHasSaved(false);
  };

  const saveChanges = async (comment: string, name?: string) => {
    clearErrors();
    setSaving(true);

    const isValidStandard = agreementType === AgreementTypeEnum.Standard && areaIds.length > 0;
    const isValidSpecific = agreementType === AgreementTypeEnum.Specific && areaIds.length > 0 && vesselIds.length > 0;
    const isValid = isValidStandard || isValidSpecific;

    if (!isValid) {
      const errorMessage =
        agreementType === AgreementTypeEnum.Standard
          ? 'Select at least one area'
          : 'Select at least one area and vessel';
      setErrors({ 'Could not save': [errorMessage] });
      return;
    }

    // We don't use mandatory on specific agreement save, and need to remove it since it may be on the data (copied from std)
    const warranties = getConditionsDelta(conditions.warranties)?.map(x =>
      agreementType === AgreementTypeEnum.Standard ? x : { ...x, mandatory: false });
    const subjectivities = getConditionsDelta(conditions.subjectivities)?.map(x =>
      agreementType === AgreementTypeEnum.Standard ? x : { ...x, mandatory: false });

    const request: WarWeb.AgreementUpdateParameters = {
      targets: saveTargets,
      ...copyModeTargets,
      coverage: getCoverageDelta(coverage),
      rates: getRatesDelta(premium.defaultRates, premium.additionalRates),
      rebates: getRebatesDelta(premium.rebates),
      knrRebates: getRebatesDelta(premium.knrRebates),
      warranties,
      subjectivities,
      updateComment: comment,
      name,
    };

    // MinimumSumInsured should not be targeted when changing daysCovered
    if (coverage.daysCovered.isDirty && request.targets?.minimumSumInsured !== undefined) {
      request.targets.minimumSumInsured = undefined;
    }

    // Targets are editable, so we might need to change them after save to show the correct alternative
    const postSaveTargets = {
      ...saveTargets,
      daysCoveredGroup: request.coverage?.daysCovered ?? saveTargets.daysCoveredGroup,
    };

    if (!getRequestHasChanges(request)) {
      onNoChanges(postSaveTargets);
      return;
    }

    const { error, data } = await updateAgreements(request);

    if (error) {
      parseErrors(true, (title, message) => open(openErrorModal(title, message)), error, setErrors);
      return;
    }

    onSuccess(postSaveTargets, data?.data);
    setSaving(false);
  };

  const canEdit =
    (agreementType === AgreementTypeEnum.Standard && isAdmin()) ||
    (agreementType === AgreementTypeEnum.Specific && isInternalUser({ onlyEditors: true }));

  const DeleteButton = agreementType === AgreementTypeEnum.Specific ? DeleteSpecificOrTradeAgreementsButton : DeleteAgreementsButton;

  return (
    <Container>
      <PromptIfDirty dirty={!hasSaved && anyAgreementChanges(coverage, premium, conditions)} />

      {agreementType === AgreementTypeEnum.Specific && <TradeIndicator />}

      <HistoryContainer>
        <DescriptionDate>
          <LastChanged type={agreementType} />
        </DescriptionDate>
      </HistoryContainer>

      {isInternalUser() && (
        <ButtonGroup>

          {isInternalUser({ onlyEditors: true }) && <DeleteButton />}
          {viewMode !== AgreementViewMode.Create && (
            <Button
              background={theme.ultraLightGray}
              icon={<FileParagraph />}
              onClick={() => setShowChangeLogModal(true)}
            >
              View Change Log
            </Button>
          )}
          {canEdit && (
            <>
              {viewMode === AgreementViewMode.Edit && (
                <Button background={theme.ultraLightGray} onClick={cancelEdit}>
                  Cancel
                </Button>
              )}

              {viewMode === AgreementViewMode.Inspect ? (
                <Button background={theme.green} icon={<Edit />} onClick={() => setViewMode(AgreementViewMode.Edit)}>
                  Edit
                </Button>
              ) : (
                <Button
                  background={theme.green}
                  icon={viewMode === AgreementViewMode.Create ? <Add /> : <Approved />}
                  // disabled={!canSave()}
                  loading={saving}
                  onClick={() => setShowSummaryModal(true)}
                >
                  {viewMode === AgreementViewMode.Create ? 'Generate' : 'Save'}
                </Button>
              )}
            </>
          )}
        </ButtonGroup>
      )}

      {showSummaryModal && <SummaryModal saveChanges={saveChanges} closeModal={() => setShowSummaryModal(false)} />}
      {showChangeLogModal && <ChangeLogModal closeModal={() => setShowChangeLogModal(false)} />}
      {showAgreementPendingModal && (
        <AgreementPendingUpdateModal closeModal={() => setShowAgreementPendingModal(false)} />
      )}
    </Container>
  );
};
