import { isAdmin } from '@/components/routing/roles';
import { Filters } from '@/models/Filters';
import {
  useEffect, useMemo, useState
} from 'react';
import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { useAgreementSelectionContext } from '@/components/pages/agreementDetails/AgreementSelectionContext';

const defaultFilters = {
  pageNumber: '1',
  pageSize: '10'
};

export const useFilters = (isEditMode: boolean, originalVesselIds: number[]) => {
  const { fleetIds } = useAgreementSelectionContext();
  const { viewMode } = useAgreementDetailsContext();
  const defaultFiltersUnderwriter = {
    ...defaultFilters,
    fleetId: fleetIds[0]
  };
  const defaultFiltersInspect = useMemo(() => ({
    ...defaultFilters,
    selectedVesselIds: originalVesselIds,
    fleetId: isAdmin() ? undefined : fleetIds[0]
  }), [fleetIds, originalVesselIds]);

  const defaultFiltersEdit = isAdmin() ? defaultFilters : defaultFiltersUnderwriter;
  const [filters, setFilters] = useState<Filters>(isEditMode ? defaultFiltersEdit : defaultFiltersInspect);

  const clearFilters = () => setFilters(isEditMode ? defaultFiltersEdit : defaultFiltersInspect);
  const initialFilters = isEditMode ? defaultFiltersEdit : defaultFiltersInspect;

  useEffect(() => {
    if (viewMode === AgreementViewMode.Inspect) {
      setFilters(prev => ({ ...prev, selectedVesselIds: originalVesselIds }));
    }
  }, [originalVesselIds]);

  useEffect(() => {
    setFilters(isEditMode ? defaultFiltersEdit : defaultFiltersInspect);
  }, [isEditMode, defaultFiltersInspect, fleetIds]);

  useEffect(() => {
    clearFilters();
  }, [filters.SelectedVesselIds]);

  return { filters, setFilters, initialFilters };
};
