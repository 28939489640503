import React from 'react';
import ReactTooltip from 'react-tooltip';
import {
  isAdmin, isBroker, isInternalUser
} from '@/components/routing/roles';
import { RichTextView } from '@/components/shared/Form/RichTextForm/RichTextView';
import {
  Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { SpaceBetweenContainer } from '@/components/shared/SpaceBetweenContainer';
import { TextConditionSummary } from '@/models/Agreement';
import { theme } from '@/utility/theme';
import {
  ConditionsStatusContainer, ConditionsTableCell, ConditionsTableHeader, TableHeaderTitle
} from '@/components/shared/TermsDetails/SettingsComponents';
import { ActionCell } from '@/components/shared/TermsDetails/textConditions/ActionCell';
import { AgreementViewMode, useAgreementDetailsContext } from '../../agreementDetails/AgreementDetailsContext';
import { ConditionsType } from '../../agreementDetails/models';
import { useAgreementDataContext } from '../../agreementDetails/AgreementDataContext';

interface ConditionsTableProps {
  conditionsType: ConditionsType;
  tableData: TextConditionSummary[];
}
export const ConditionsTable = ({ conditionsType, tableData }: ConditionsTableProps) => {
  const { viewMode } = useAgreementDetailsContext();
  const { conditions, setConditions } = useAgreementDataContext();

  const isEdit = viewMode !== AgreementViewMode.Inspect;

  const handleInputChange = (checked: boolean, termId: number) => {
    const updatedConditions = [...conditions[conditionsType]];
    const condition = updatedConditions.find(a => a.termId === termId);
    if (!condition) return;
    if (isAdmin()) {
      condition.mandatoryOptions = condition.mandatoryOptions.map(x => ({ ...x, option: checked }));
    }
    condition.includedOptions = condition.includedOptions.map(x => ({ ...x, option: checked }));
    condition.isDirty = true;
    condition.bySystem = false;
    if (condition.action !== 'Add') condition.action = 'Edit';
    setConditions({ ...conditions, [conditionsType]: updatedConditions });
  };

  const renderTableHeaders = (headers: string[], actionTitles?: string[]) => headers.map((h, i) => (
    <React.Fragment key={h}>
      <TableHeaderTitle noBorder span={isBroker() ? 1 : 2}>{h}</TableHeaderTitle>
      {actionTitles && <ConditionsTableHeader noBorder>{actionTitles[i]}</ConditionsTableHeader>}
    </React.Fragment>
  ));

  const renderRows = (
    conditionsData: TextConditionSummary[]
  ) => (
    <>
      {conditionsData.map(c => (
        <TableRow key={c.termId}>
          {isInternalUser() && (
            <TableCell top>
              {c.termId > 0 && c.termId}
            </TableCell>
          )}
          <ConditionsTableCell>
            <SpaceBetweenContainer>
              {/* TODO: Temporary fix until non-included text conditions aren't returned from the backend for Brokers */}
              {isBroker() && !c.includedOptions.some(x => x.option) ? null : <RichTextView text={c.text} />}
              {isEdit && (c.isDirty || c.bySystem) && (
                <>
                  <ConditionsStatusContainer
                    data-tip={c.bySystem && c.includedOptions.some(it => it.option) ?
                      'Text conditions were automatically added to comply with global limits for selected areas' : ''}
                    isDirty={c.isDirty}
                    bySystem={c.bySystem}>
                    {c.bySystem ? `${c.mandatoryOptions.some(x => x.option) ? 'Mandatory' : 'Recommended'}` : 'Edited'}
                  </ConditionsStatusContainer>
                  <ReactTooltip backgroundColor={theme.marineBlue} effect="solid" />
                </>
              )}
            </SpaceBetweenContainer>
          </ConditionsTableCell>
          {isInternalUser() && (
            <TableCell center>
              <ActionCell onChange={handleInputChange} condition={c} />
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );

  const tableHeaders = [conditionsType];
  const getActionTitles = () => {
    if (isBroker()) return undefined;
    return isEdit ? ['Include'] : ['Included'];
  };

  return (
    <Table layout={isBroker() ? undefined : '0.5fr 4fr 1fr'} columns={isBroker() ? 1 : 3}>
      {renderTableHeaders(tableHeaders, getActionTitles())}
      {renderRows(tableData.filter(x => x.action !== 'Delete').sort((a, b) => a.termId - b.termId))}
    </Table>
  );
};
