import React, { FC } from 'react';
import { Close } from '@instech/icons';
import { TableCell } from '@/components/shared/Table/Table';
import { agreementErrorKeys, ItemSummary } from '@/models/Agreement';
import {
  formatDecimal,
  formatDecimalsInText, formatPercentage, formatRange
} from '@/utility/formatter';
import { TextFieldWithAction } from '@/components/shared/Form/fields/TextFieldWithAction';
import { Validator } from '@/components/shared/Validator';
import { MaxNumberTimeValue } from '@/utility/globals';
import { hasValue } from '@/components/shared/TermsDetails/premiumRebates/RatesTable';
import { AgreementViewMode, useAgreementDetailsContext } from '../../agreementDetails/AgreementDetailsContext';
import { useAgreementDataContext } from '../../agreementDetails/AgreementDataContext';

type RebateType = 'rebates' | 'knrRebates';

interface RebateCellProps {
  type: RebateType;
  rebate: ItemSummary<number>;
}
export const RebateCell: FC<RebateCellProps> = ({ rebate, type }) => {
  const { viewMode } = useAgreementDetailsContext();
  const { premium, setPremium } = useAgreementDataContext();

  const updateValue = (val: string | null, name: string) => {
    if (!premium) return;

    const temp = [...premium[type]];
    const cell = temp.find(x => x.shortName === name);
    if (!cell) return;
    cell.value = val ? parseFloat(val) : undefined;
    cell.isDirty = true;
    setPremium({ ...premium, [type]: temp });
  };

  const handleRebateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    updateValue(value, name);
  };

  const action = hasValue(rebate) ?
    {
      icon: <Close />,
      onClick: () => updateValue(null, rebate.shortName)
    } : undefined;

  return (
    <TableCell>
      {viewMode !== AgreementViewMode.Inspect ? (
        <Validator keys={[`${agreementErrorKeys[type]}=${rebate.id})`]} formatter={formatDecimalsInText}>
          <TextFieldWithAction type="number"
            name={rebate.shortName}
            numberOfCharacters={5}
            value={rebate.value != null ? rebate.value : ''}
            placeholder={formatRange(rebate)}
            action={action}
            postfix="%"
            minValue={0}
            maxValue={MaxNumberTimeValue}
            onChange={e => handleRebateChange(e)}
            dirty={rebate.isDirty}
          />

        </Validator>
      )
        : formatRange(rebate, formatDecimal, '%') ?? formatPercentage(rebate.value)}
    </TableCell>
  );
};
