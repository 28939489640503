import {
  Redirect, Route, Switch
} from 'react-router';
import { isInternalUser } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { useQueryString } from '@/hooks/useQuery';
import { AgreementsOverviewPage } from '../agreementsOverview/AgreementsOverviewPage';
import { AgreementDetailsPage } from '../agreementDetails/AgreementDetailsPage';
import { KidnapRansomTermsPage } from '../knrTerms/KnRTermsPage';
import { ExcludedAreasPage } from '../excludedAreas/ExcludedAreasPage/ExcludedAreasPage';
import { TermsConditionsPage } from '../specificTermsConditions/TermsConditionsPage';
import { TermsDetailsPage } from '../specificTermsDetails/TermsDetailsPage';
import { DefaultTermsConditionsPage } from '../defaultTermsConditions/DefaultTermsConditionsPage';
import { AgreementsPageProvider } from './AgreementsPageContext';
import { EditExcludedAreasPage } from '../excludedAreas/EditExcludedAreasPage/EditExcludedAreasPage';
import { ExcludedAreaPageProvider } from '../excludedAreas/context/ExcludedAreaPageContext';
import { DefaultTermsDetailsPage } from '../defaultTermsDetails/DefaultTermsDetailsPage';
import { BrokerTermsDetailsPage } from '../specificTermsDetailsBroker/BrokerTermsDetailsPage';

const BrokerRoutes = () => {
  const search = useQueryString();
  return (
    <>
      <Switch>
        <AgreementsPageProvider>
          <Route exact path={Routes.terms}>
            <AgreementsOverviewPage>
              <TermsConditionsPage />
            </AgreementsOverviewPage>
          </Route>
          <Route exact path={Routes.termsDetails}>
            <AgreementDetailsPage>
              <BrokerTermsDetailsPage />
            </AgreementDetailsPage>
          </Route>
        </AgreementsPageProvider>
      </Switch>
      <Switch>
        <Redirect exact from={Routes.termsSpecificDetails} to={Routes.termsDetails} />
        <Redirect exact from={Routes.termsSpecific} to={{ pathname: Routes.terms, search }} />
      </Switch>
    </>
  );
};

const AdminUWRoutes = () => {
  const search = useQueryString();
  return (
    <>
      <Switch>
        <AgreementsPageProvider>
          <Route exact path={Routes.termsDefault}>
            <AgreementsOverviewPage>
              <DefaultTermsConditionsPage />
            </AgreementsOverviewPage>
          </Route>
          <Route exact path={`${Routes.termsDefaultDetails}/:tab?`}>
            <AgreementDetailsPage>
              <DefaultTermsDetailsPage />
            </AgreementDetailsPage>
          </Route>
          <ExcludedAreaPageProvider>
            <Route exact path={Routes.termsExcludedAreas}>
              <ExcludedAreasPage />
            </Route>
            <Route exact path={Routes.termsEditExcludedAreas}>
              <EditExcludedAreasPage />
            </Route>
          </ExcludedAreaPageProvider>
        </AgreementsPageProvider>
      </Switch>

      <Switch>
        <AgreementsPageProvider>
          <Route exact path={Routes.termsSpecific}>
            <AgreementsOverviewPage>
              <TermsConditionsPage />
            </AgreementsOverviewPage>
          </Route>
          <Route exact path={[Routes.termsSpecificDetails, Routes.termsSpecificCreate]}>
            <AgreementDetailsPage>
              <TermsDetailsPage />
            </AgreementDetailsPage>
          </Route>
          <Route exact path={Routes.termsKidnapRansom}>
            <KidnapRansomTermsPage />
          </Route>
        </AgreementsPageProvider>
        <Redirect exact from={Routes.terms} to={{ pathname: Routes.termsSpecific, search }} />
        <Redirect exact from={Routes.termsDetails} to={Routes.termsSpecificDetails} />

      </Switch>
    </>
  );
};

export const buildAgreementsRoutes = () => {
  if (isInternalUser()) {
    return <AdminUWRoutes />;
  }
  return <BrokerRoutes />;
};
