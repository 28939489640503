import { isUnderwriter } from '@/components/routing/roles';
import { FilterBar } from '@/components/shared/FilterBar';
import { FilterButton } from '@/components/shared/FilterButton';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { SelectionBar } from '@/components/shared/SelectionBar';
import {
  getDelimiters, getHeaders, TableHeader
} from '@/components/shared/Table/core/Components';
import { FilterableTableHeaders, Table } from '@/components/shared/Table/Table';
import useDebounce from '@/hooks/useDebouncedValue';
import { Filters } from '@/models/Filters';
import { SortedHeader, SortOrder } from '@/models/SortOrder';
import { basepath, useVessels } from '@/services/vesselsService';
import { WarWeb } from '@/war';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAgreementSelectionContext } from '@/components/pages/agreementDetails/AgreementSelectionContext';
import { FilterableTableHeadersProvider } from '@/components/pages/defaultTermsConditions/FilterableTableHeadersContext';
import { SpaceBetweenContainer } from '@/components/shared/SpaceBetweenContainer';
import { tableSections } from './headers';
import { AppliesToRow } from './AppliesToRow';

const StatusButtons = styled.div`
  display: flex;
`;

const defaultSorting = {
  propertyName: 'Name',
  sortOrder: SortOrder.Ascending
};

interface AdminViewProps {
  vessels: WarWeb.SearchResult<WarWeb.MarineMatch>;
  filters: Filters;
  initialFilters: Filters;
  setFilters: (filters: Filters) => void;
  canEditVessel: boolean;
  originalVesselIds: number[];
}
export const VesselTable = ({ vessels, filters, setFilters, canEditVessel, initialFilters, originalVesselIds }: AdminViewProps) => {
  const debouncedFilters = useDebounce(filters, 500);
  const { vesselIds, setVesselIds, setFleetIds } = useAgreementSelectionContext();
  const [sortedHeader, setSortedHeader] = useState<SortedHeader | undefined>(defaultSorting);

  const [selectAll, setSelectAll] = useState<boolean>();
  const { data: allVessels } = useVessels(canEditVessel && selectAll ? { ...debouncedFilters, pageNumber: '1', pageSize: '1000' } : undefined);

  useEffect(() => {
    if (selectAll) {
      if (allVessels) {
        const preExistingPlusAll = [...new Set(vesselIds.concat(allVessels.items.map(v => v.vesselId!)))];
        setVesselIds(preExistingPlusAll);
      }
    }
  }, [allVessels, selectAll, setVesselIds]);

  useEffect(() => {
    if (selectAll === false) {
      setVesselIds([]);
    }
  }, [selectAll]);

  const headers = getHeaders(tableSections);

  const toggleVessels = () => {
    setSelectAll(!selectAll);
  };

  const resetFleet = () => {
    const { fleetId: fleetIdFilter, selectedVesselIds, ...rest } = filters;
    setVesselIds([]);
    setFilters(rest);
    setFleetIds([]);
  };

  return (
    <FilterableTableHeadersProvider
      headers={headers}
      columnMetadata={vessels.columnMetadata}
      initialFilters={initialFilters}
      filters={filters}
      setFilters={setFilters}
      sortedHeader={sortedHeader}
      setSortedHeader={setSortedHeader}
      isLoading={false}
    >
      <SpaceBetweenContainer>
        <SelectionBar
          type="vessel"
          parameterName="selectedVesselIds"
          editMode={canEditVessel}
          selection={vesselIds}
          selected={!!filters?.selectedVesselIds && filters.selectedVesselIds.length > 0}
          initialSelection={originalVesselIds}
          setter={setVesselIds}
          resetSelectionCallback={() => setSelectAll(undefined)}
        />
        {canEditVessel && (
        <>
          {isUnderwriter() &&
          <FilterButton label="Reset fleet" toggle={resetFleet} />}
          <StatusButtons>
            <FilterBar />
          </StatusButtons>
        </>
        )}
      </SpaceBetweenContainer>

      <Table columns={headers.length + (canEditVessel ? 1 : 0)} striped>
        {canEditVessel && (
        <TableHeader>
          <Checkbox
            name="selectAll"
            checked={selectAll}
            onChange={toggleVessels}
          />
        </TableHeader>
        )}
        <FilterableTableHeaders
          endpoint={basepath}
          delimiters={getDelimiters(tableSections)}
          wide
        />
        {vessels.items?.filter(v => canEditVessel || vesselIds?.some(x => x === v.vesselId))?.map(vessel => (
          <AppliesToRow key={vessel.id} row={vessel} />
        ))}
      </Table>
    </FilterableTableHeadersProvider>
  );
};
