import { Conditions, Premium } from '@/components/pages/agreementDetails/models';
import {
  CoverageSummary, ItemSummary, RateItemSummary, TextConditionSummary
} from '@/models/Agreement';
import { WarWeb } from '@/war';

export const getCoverageDelta = (cov: CoverageSummary): WarWeb.CoverageSpecification | undefined => {
  const deltaDaysCovered = Number.isNaN(cov?.daysCovered.value) ? 0 : cov.daysCovered.value;
  const deltaQuoteValidHours = Number.isNaN(cov?.quoteValidHours.value) ? 0 : cov.quoteValidHours.value;
  const deltaProrataDays = Number.isNaN(cov?.maximumProRataDays.value) ? 0 : cov.maximumProRataDays.value;
  const deltaMaximumDays = Number.isNaN(cov?.maximumDaysCovered?.value) ? 0 : cov.maximumDaysCovered?.value;

  const delta : WarWeb.CoverageSpecification = {
    daysCovered: cov?.daysCovered.isDirty ? deltaDaysCovered : undefined,
    quoteValidHours: cov?.quoteValidHours.isDirty ? deltaQuoteValidHours : undefined,
    contractType: cov?.contractType.isDirty ? cov.contractType.value : undefined,
    maximumProRataDays: cov?.maximumProRataDays.isDirty ? deltaProrataDays : undefined,
    maximumDaysCovered: cov?.maximumDaysCovered?.isDirty ? deltaMaximumDays : undefined
  };
  if (delta.daysCovered != null ||
      delta.quoteValidHours != null ||
      delta.contractType ||
      delta.maximumProRataDays != null ||
      delta.maximumDaysCovered != null) {
    return delta;
  }
  return undefined;
};

export const getRatesDelta = (rates: RateItemSummary[], additionalRates: RateItemSummary[]): WarWeb.RateValueUpdateSpecification[] | undefined => {
  const deltaRates = rates.filter(x => x.isDirty);
  const deltaAdditionalRates = additionalRates.filter(x => x.isDirty);
  if (deltaRates.length === 0 && deltaAdditionalRates.length === 0) return undefined;
  const delta = deltaRates ? deltaRates.concat(deltaAdditionalRates) : deltaAdditionalRates;

  return delta.map(x => ({
    changeType: 'Absolute',
    interestTypeId: x.id,
    minimumAdditionalPremium: Number.isNaN(x.minPremium.value) ? 0 : x.minPremium.value,
    standalone: x.standalone,
    tier: x.tier,
    value: Number.isNaN(x.value) ? 0 : x.value
  }));
};

export const getRebatesDelta = (rebates: ItemSummary<number>[]): WarWeb.RebateSpecification[] | WarWeb.KnrRebateSpecification[] | undefined => {
  const delta = rebates.filter(x => x.isDirty);
  if (delta.length === 0) return undefined;

  return delta.map(x => ({ changeType: 'Absolute', rebateId: x.id, tier: x.tier, value: Number.isNaN(x.value) ? 0 : x.value, knrRebateId: x.id }));
};

export const getConditionsDelta = (conditions: TextConditionSummary[]): WarWeb.TextConditionSpecification[] | undefined => {
  const delta = conditions.filter(c => c.isDirty);
  if (delta.length === 0) return undefined;
  return delta.map(c => ({
    textConditionId: c.termId,
    textConditionText: c.text,
    action: c.action,
    tier: c.tier,

    // While using data model as view model, we have to handle the scenario when options are empty []
    // When we map to request model, we only need termId, tier and option (checked) so we can make a
    // simple minimal/placeholder entry
    ...(c.includedOptions?.some(x => x) && ({
      included: c.includedOptions[0].option,
    })),
    ...(c.mandatoryOptions?.some(x => x) && ({
      mandatory: c.mandatoryOptions[0].option
    }))
  }));
};

// TODO: useAgreementDeltas hook for getting all the current deltas/changes?
// Can have two methods, or better, combine default/specific contexts, can be used for change list in apply changes modal and perhaps simplify the method below

export const anyAgreementChanges = (
  coverage: CoverageSummary,
  premium: Premium,
  conditions: Conditions
) => {
  const anyCoverageChanges = !!getCoverageDelta(coverage);
  const anyRatesChanges = getRatesDelta(premium.defaultRates, premium.additionalRates)?.some(x => x);
  const anyRebatesChanges = getRebatesDelta(premium.rebates)?.some((x: any) => x) || getRebatesDelta(premium.knrRebates)?.some((x: any) => x);
  const anyConditionsChanges = getConditionsDelta(conditions.warranties)?.some((x: any) => x)
  || getConditionsDelta(conditions.subjectivities)?.some((x: any) => x);

  const anyChanges = anyCoverageChanges || anyRatesChanges || anyRebatesChanges || anyConditionsChanges;
  return !!anyChanges;
};
