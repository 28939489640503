import { isAdmin } from '@/components/routing/roles';
import { useSelectedQuote } from '@/components/shared/Bow/useSelectedQuote';
import { Label, SidebarSubHeader } from '@/components/shared/BowSidebar/Components';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { Box } from '@/components/shared/Box';
import { RichTextView } from '@/components/shared/Form/RichTextForm/RichTextView';
import { checkAction, HateoasHandler } from '@/components/shared/HateoasHandler';
import { RemainingValidations } from '@/components/shared/RemainingValidations';
import { Validator } from '@/components/shared/Validator';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { concatNullsafe, groupBy } from '@/utility/arrayHelpers';
import { Insify, WarWeb } from '@/war';
import { Edit } from '@instech/icons';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { TextConditionAction } from '@/models/TextConditionAction';
import { QuoteActions } from '../bows/details/quoteActionsMap';
import { steps } from './bowSteps';
import { useBowWizardStateContext } from './BowWizardStateContext';
import { ConditionsLocationsModal, NewTermForLocation } from './components/ConditionsPerLocationModal';

const TermsConditionsContainer = styled.div`
  display: flex;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1em;
`;

const EqualBox = styled(Box) <{ header: string }>`
  display: flex;
  flex: 1 1 0px;

  &:first-child {
    margin-right: 2em;
  }
`;

const Content = styled.div`
  padding: 0 1em 1em  2em;
`;

const Condition = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding: 1rem 0;
  padding-right: 4px;

  svg {
    width: 15px;
  }
`;

const StyledEdit = styled(Edit)`
  cursor: pointer;
`;

const Text = styled.div`
  width: 90%;
`;

interface Destinations {
  areaId: string;
  locationId: number;
  coveredArea: string;
  name: string;
  links: WarWeb.HyperMediaLink[];
}

export enum TermsType {
  Warranties = 1,
  Subjectivities = 2
}

interface TermsGroupProps {
  termsGrouped: any;
  locations: WarWeb.Location[];
  readOnly: boolean;
  setShowTermsModal: (config: any) => void;
}

const TermsGroup = ({ termsGrouped, locations, readOnly, setShowTermsModal }: TermsGroupProps) => {
  const { isMultiDestination } = useQuoteContext();

  const selectedQuote = useSelectedQuote();
  if (!selectedQuote) return null;

  return (
    <React.Fragment key={termsGrouped[0].locations?.map((l: WarWeb.Location) => l.id).join('-') ?? 'general'}>
      <SidebarSubHeader border={isMultiDestination}>
        <Label>{isMultiDestination ? termsGrouped[0].locationsHeader : ''}</Label>
        <HateoasHandler
          links={readOnly ? undefined : selectedQuote.links}
          action={QuoteActions.AddTextCondition}
          editVariant={(
            <StyledEdit onClick={() => setShowTermsModal({ location: locations[0] })} />
          )} />
      </SidebarSubHeader>
      {termsGrouped?.map((w: Insify.War.Bows.Core.Models.Term.Term) => (
        <Condition key={w.id}>
          {isAdmin() && <small>{w.id}</small>}
          <Text>
            <RichTextView text={w.text} />
          </Text>
        </Condition>
      ))}
    </React.Fragment>
  );
};

interface TermsPerLocation {
  [locationId: number]: number[]
}

interface ModalConfig {
  location: WarWeb.Location;
}

interface TermsByAreaProps {
  type: TermsType;
  terms: Insify.War.Bows.Core.Models.Term.Term[];
  readOnly?: boolean;
}
export const TermsByArea = ({ type, terms, readOnly = false }: TermsByAreaProps) => {
  const { hateoasExecutor } = useHateoasExecutorContext();

  const [showTermsModal, setShowTermsModal] = useState<ModalConfig>();

  const { isMultiDestination } = useQuoteContext();
  const selectedQuote = useSelectedQuote();
  if (!selectedQuote) return null;

  const destinations = selectedQuote.coveredAreas?.flatMap(ca => {
    const dest = ca.itinerary?.flatMap(d => ({
      areaId: d.id,
      locationId: d.port.id,
      coveredArea: ca.name,
      name: d.port.name,
      links: d.links,
      configuration: d.configuration
    }));
    if (ca.coveredAreaType === 'OceanArea') {
      return dest.concat({
        areaId: ca.id,
        locationId: ca.areaId,
        coveredArea: ca.name,
        name: ca.name,
        links: ca.links,
        configuration: ca.configuration
      });
    }
    return dest;
  });

  const updateConditions = async (textCondition: NewTermForLocation, conditionAction: TextConditionAction) => {
    const destination = destinations?.find(d => d.locationId === textCondition.locationId);
    const links = textCondition.locationId === -1 ? selectedQuote.links : destination?.links;
    if (!links) return;
    if (textCondition.locationId !== -1 && !destination) return;
    const action = conditionAction === 'add' ? QuoteActions.AddTextCondition : QuoteActions.RemoveTextCondition;

    const updateTextConditionsRequest = {
      textConditionId: textCondition.id,
      destinationId: destination?.areaId ?? -1,
      textConditionType: type
    };
    await hateoasExecutor(links, action, updateTextConditionsRequest);
  };

  const hasDestinations = destinations && destinations.length > 0;
  if (!hasDestinations) return null;

  const generalDestination: WarWeb.Location = { id: -1, name: 'General (all)' };
  const singleDestination: WarWeb.Location = { id: destinations[0].locationId, name: destinations[0].name };

  const includedConditionsPerLocation: TermsPerLocation = {};
  destinations?.forEach(d => {
    includedConditionsPerLocation[d.locationId] =
      d.configuration?.textConditions
        ?.filter(c => c.textConditionType === (type === TermsType.Warranties ? 'Warranty' : 'Subjectivity'))
        ?.map((tc: any) => tc.id);
  });

  if (isMultiDestination) {
    // add on any GENERAL terms. Since "GENERAL" is not a *location*, as such, these terms can not be found in any configuration,
    // but must rather be taken from the W/S on  the quote level (the ones with no `locations`)
    includedConditionsPerLocation[generalDestination.id] =
      terms?.filter((t: Insify.War.Bows.Core.Models.Term.Term) => t.locations === null)?.map((u: Insify.War.Bows.Core.Models.Term.Term) => u.id);
  }

  const locationsOptions = concatNullsafe(destinations?.filter(i => checkAction(i.links, QuoteActions.AddTextCondition))?.map((d: Destinations) => ({
    id: d.locationId,
    name: d.name
  })), isMultiDestination ? [generalDestination] : []);

  return (
    <>
      {(!terms || terms.length === 0) ? (
        <SidebarSubHeader>
          <div>No terms specified</div>
          <HateoasHandler
            links={readOnly ? undefined : selectedQuote.links}
            action={QuoteActions.AddTextCondition}
            editVariant={(
              <StyledEdit onClick={() => setShowTermsModal({
                location: (isMultiDestination || !singleDestination) ? generalDestination : singleDestination
              })} />
            )} />
        </SidebarSubHeader>
      ) : (
        <>
          {groupBy(
            terms.map((w: Insify.War.Bows.Core.Models.Term.Term) =>
              ({ ...w, locationsHeader: w.locations?.map((l: WarWeb.Location) => l.name).join(', ') ?? 'general' })),
            (condition: any) => [condition.locationsHeader]
          )
            .map((termsGrouped: any) => (
              <TermsGroup
                termsGrouped={termsGrouped}
                readOnly={readOnly}
                setShowTermsModal={setShowTermsModal}
                locations={isMultiDestination ? termsGrouped[0].locations ?? [generalDestination] : [singleDestination]} />
            ))}
        </>
      )}

      {!!showTermsModal && !readOnly && destinations && destinations.length > 0 && (
        <ConditionsLocationsModal
          closeModal={() => setShowTermsModal(undefined)}
          conditionsType={type === TermsType.Warranties ? 'warranties' : 'subjectivities'}
          includedConditions={includedConditionsPerLocation}
          updateConditions={updateConditions}
          currentLocation={isMultiDestination ? showTermsModal?.location ?? generalDestination : singleDestination}
          locations={locationsOptions} />
      )}
    </>
  );
};

export const TermsAndConditionsStep = () => {
  const selectedQuote = useSelectedQuote();
  const { setStepDone } = useBowWizardStateContext();

  useEffect(() => {
    if (selectedQuote) {
      setStepDone(true); // TODO: Maybe there will be some steps that must be completed later?
    }
  }, [selectedQuote]);

  return (
    <>
      <ErrorContainer>
        <Validator keys={steps.filter(s => s.step !== 4)?.flatMap(u => u.warningsKeys.map(v => `/${v}/`))} hideWarnings />
        <RemainingValidations rootElement={selectedQuote} level="errors" />
      </ErrorContainer>

      <TermsConditionsContainer>
        <EqualBox header="Warranties">
          <Content>
            <TermsByArea type={TermsType.Warranties} terms={selectedQuote?.warranties || []} />
          </Content>
        </EqualBox>

        <EqualBox header="Subjectivities">
          <Content>
            <TermsByArea type={TermsType.Subjectivities} terms={selectedQuote?.subjectivities || []} />
          </Content>
        </EqualBox>
      </TermsConditionsContainer>
    </>
  );
};
