import { Close } from '@instech/icons';
import React from 'react';
import { TextFieldWithAction } from '@/components/shared/Form/fields/TextFieldWithAction';
import {
  renderTableHeaders, Table, TableCell, TableHeaderCellSpan, TableRow
} from '@/components/shared/Table/Table';
import { ItemSummary } from '@/models/Agreement';
import { AgreementTiers } from '@/utility/agreementTiers';
import { unique } from '@/utility/arrayHelpers';
import {
  formatDecimal, formatDecimalsInText, formatPercentage, formatRange, getInputValue
} from '@/utility/formatter';
import { keysOf } from '@/utility/objectHelpers';
import { Validator } from '@/components/shared/Validator';
import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { useAgreementDataContext } from '@/components/pages/agreementDetails/AgreementDataContext';
import { MaxNumberTimeValue } from '@/utility/globals';
import { HorizontalTableLayout, TableHeaderTitle } from '@/components/shared/TermsDetails/SettingsComponents';
import { hasValue } from './RatesTable';

type RebateType = 'rebates' | 'knrRebates';

export const RebatesTable = () => {
  const { viewMode } = useAgreementDetailsContext();
  const { premium, setPremium } = useAgreementDataContext();
  if (!premium) return null;

  const rebatesHeaders = unique(premium.rebates, ['shortName']).map(x => x.shortName);
  const knrHeaders = unique(premium.knrRebates, ['shortName']).map(x => x.shortName);

  const relevantTiers = keysOf(AgreementTiers).filter(x => x !== 'Minimum');

  const updateValue = (val: string | null, item: ItemSummary<number>, type: RebateType) => {
    const tempRebates = [...premium[type]];
    const cell = tempRebates.find(x => x.id === item.id && x.tier === item.tier);
    if (!cell) return;

    const value = val ? parseFloat(val) : undefined;
    cell.value = value;
    cell.isDirty = true;
    setPremium({ ...premium, [type]: tempRebates });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, item: ItemSummary<number>, type: RebateType) => {
    updateValue(e.target.value, item, type);
  };

  const renderEditCell = (rebate: ItemSummary<number>, type: RebateType) => {
    const action = hasValue(rebate) ?
      {
        icon: <Close />,
        onClick: () => updateValue(null, rebate, type)
      } : undefined;

    const percentageField = viewMode !== AgreementViewMode.Inspect ? (
      <Validator formatter={formatDecimalsInText}>
        <TextFieldWithAction type="number"
          maxValue={MaxNumberTimeValue}
          name={`${rebate.id}`}
          numberOfCharacters={5}
          value={getInputValue(rebate.value)}
          placeholder={formatRange(rebate)}
          action={action}
          postfix="%"
          onChange={e => handleInputChange(e, rebate, type)}
          dirty={rebate.isDirty} />

      </Validator>
    ) : formatRange(rebate, formatDecimal, '%') ?? formatPercentage(rebate.value);

    return (
      <TableCell key={`${rebate.id}`}>
        {percentageField}
      </TableCell>
    );
  };

  const renderRebatesTable = (type: RebateType) =>
    relevantTiers.map(tier => {
      const tieredRebates = premium[type].filter(r => r.tier === tier);
      return (
        <TableRow key={tier}>
          {type === 'rebates' && <TableCell>{AgreementTiers[tier]}</TableCell>}
          {tieredRebates.map(rebate => renderEditCell(rebate, type))}
        </TableRow>
      );
    });

  return (
    <HorizontalTableLayout>
      <Table striped columns={rebatesHeaders.length + 1}>
        <TableHeaderCellSpan span={rebatesHeaders.length + 1} />
        <TableHeaderTitle>Rebates</TableHeaderTitle>
        {renderTableHeaders(rebatesHeaders)}
        {renderRebatesTable('rebates')}
      </Table>
      <Table striped columns={knrHeaders.length}>
        <TableHeaderCellSpan span={knrHeaders.length}>K&R</TableHeaderCellSpan>
        {renderTableHeaders(knrHeaders)}
        {renderRebatesTable('knrRebates')}
      </Table>
    </HorizontalTableLayout>
  );
};
