import { PageHeader } from '@/components/layout/PageHeader';
import { Routes } from '@/components/routing/routes';
import styled from 'styled-components';
import { AppliesToSelection } from '@/components/shared/TermsDetails/AppliesToSelection/AppliesToSelection';
import { HeaderContent } from '../specificTermsDetails/HeaderContent';
import { SettingsBroker } from './SettingsBroker';

const ContentContainer = styled.div<{ isEdit: boolean }>`
  display: grid;
  grid-template-columns: ${props => (props.isEdit ? '0.5fr 6fr' : 'auto')};
  grid-template-rows: auto;
  column-gap: 2rem;

  grid-template-areas:
            'settings'
            'applies-to';

  .settings {
    grid-area: settings;
    overflow: auto;
  }
  .applies-to {
    grid-area: applies-to;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  margin-bottom: 1.5rem;
`;

export const BrokerTermsDetailsPage = () => (
  <>
    <HeaderContainer>
      <PageHeader
          header={<HeaderContent setIsEditTradeAgreementNameOpen={() => { }} />}
          breadcrumb={{
            label: 'Terms & Conditions',
            to: Routes.terms
          }}
        />
    </HeaderContainer>
    <ContentContainer isEdit={false}>
      <Container className="settings">
        <SettingsBroker />
      </Container>
      <Container className="applies-to">
        <AppliesToSelection />
      </Container>
    </ContentContainer>
  </>
);
