import { v4 as uuidv4 } from 'uuid';
import { theme } from '@/utility/theme';
import { PropsWithChildren } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const Container = styled.div`
  .tooltip {
    color: ${theme.marineBlue};
    max-width: 200px;
    line-height: 1.5em;
    white-space: normal;
    box-shadow: 3px 6px 20px 12px rgba(72,135,177,0.2);
  }
`;

interface MultipleValuesProps {
  options?: any[];
  render: (o: any) => void;
}

export const MultipleValues = ({ options, render, children }: PropsWithChildren<MultipleValuesProps>) => {
  const randomId = uuidv4();

  if (!options || options.length === 0) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <Container>
      <span data-for={randomId} data-tip="null">
        {children}
      </span>
      <ReactTooltip id={randomId} backgroundColor={theme.white} effect="solid" className="tooltip">
        {options.map(o => (
          render(o)
        ))}
      </ReactTooltip>
    </Container>
  );
};
