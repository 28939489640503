import { isBroker } from '@/components/routing/roles';
import {
  renderTableHeaders, Table, TableCell, TableHeaderCellSpan, TableRow
} from '@/components/shared/Table/Table';
import { HorizontalTableLayout, TableHeaderTitle } from '@/components/shared/TermsDetails/SettingsComponents';
import { RatesTable } from '@/components/shared/TermsDetails/premiumRebates/RatesTable';
import { KnRTable } from './KnRTable';
import { RebateCell } from './RebateCell';
import { useAgreementDataContext } from '../../agreementDetails/AgreementDataContext';

export const PremiumAndRebates = () => {
  const { premium } = useAgreementDataContext();
  if (!premium) return null;

  const rebatesHeaders = premium.rebates.map(x => x.shortName);
  const knrHeaders = premium.knrRebates.map(x => x.shortName);

  return (
    <>
      <RatesTable agreementType="SpecificAgreement" />

      <HorizontalTableLayout>
        {isBroker() && !premium.rebates.some(x => x.value) ? null : (
          <Table columns={rebatesHeaders.length + 1}>
            <TableHeaderCellSpan span={rebatesHeaders.length + 1} />
            <TableHeaderTitle>Rebates</TableHeaderTitle>
            {renderTableHeaders(rebatesHeaders)}
            <TableRow top>
              <TableCell>Value</TableCell>
              {premium.rebates.map(rebate =>
                <RebateCell type="rebates" key={rebate.id} rebate={rebate} />)}
            </TableRow>
          </Table>
        )}
        {isBroker() && !premium.knrRebates.some(x => x.value) ? null : (
          <Table columns={knrHeaders.length}>
            <TableHeaderCellSpan span={knrHeaders.length}>K&R</TableHeaderCellSpan>
            {renderTableHeaders(knrHeaders)}
            <TableRow top>
              {premium.knrRebates.map(knr =>
                <RebateCell type="knrRebates" key={knr.id} rebate={knr} />)}
            </TableRow>
          </Table>
        )}
      </HorizontalTableLayout>
      {isBroker() && <KnRTable />}
    </>
  );
};
