import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { WarWeb } from '@/war';
import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { useAgreementSelectionContext } from '@/components/pages/agreementDetails/AgreementSelectionContext';
import { useIsBulkEditing } from '@/components/pages/agreements/useIsBulkEditing';
import { addOrRemove } from '@/utility/arrayHelpers';
import { TableCell, TableRow } from '@/components/shared/Table/Table';

interface AppliesToRowProps {
  row: WarWeb.MarineMatch
}
export const AppliesToRow = ({ row }: AppliesToRowProps) => {
  const { viewMode } = useAgreementDetailsContext();
  const { setVesselIds, vesselIds } = useAgreementSelectionContext();
  const isBulkEdit = useIsBulkEditing();

  const isSelected = vesselIds?.some(vesselId => vesselId === row.vesselId);
  const canEditVessel = viewMode !== AgreementViewMode.Inspect && !isBulkEdit;

  const onChangeHandler = (id: number, fleetId?: number) => {
    const updatedSelection = [...vesselIds];
    addOrRemove(updatedSelection, id);
    setVesselIds(updatedSelection);
  };

  return (
    <TableRow hover onClick={canEditVessel ? () => onChangeHandler(row.vesselId!, row.fleetId) : undefined}>
      {canEditVessel && (
        <TableCell>
          <Checkbox
            name={row.id.toString()}
            checked={isSelected}
            onChange={() => onChangeHandler(row.vesselId!, row.fleetId)}
          />
        </TableCell>
      )}
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.fleetName}</TableCell>
      <TableCell>{row.clientName}</TableCell>
      <TableCell>{row.imo}</TableCell>
      <TableCell>{row.vesselTypeName}</TableCell>
    </TableRow>
  );
};
