import { TableCell, TableHeader } from '@/components/shared/Table/Table';
import styled from 'styled-components';
import { theme } from '@/utility/theme';

export const TableHeaderTitle = styled(TableHeader)`
  font-size: 18px;
  text-transform: capitalize;
`;

export const DuoTableLayout = styled.div`
  display: flex;

  > div {
    margin-right: 3rem;
    min-width: 25%;
    max-width: 500px;
  }
`;

export const HorizontalTableLayout = styled.div`
  display: flex;

  ${TableHeader} {
    height: 45px;
  }

  > div {
    margin-right: 2rem;
    min-width: 25%
  }
`;

const TableHeaderContainer = styled(TableHeader)`
  flex-direction: column;
`;
const Subtitle = styled.span`
  font-weight: normal;
`;

export const renderCoverageTableHeaders = (isStandardAgreement?: boolean) => (
  <>
    <TableHeaderTitle>Coverage</TableHeaderTitle>
    <TableHeaderContainer>
      Quote valid
      <Subtitle>Hours</Subtitle>
    </TableHeaderContainer>
    <TableHeader>Days covered</TableHeader>
    {isStandardAgreement && <TableHeader>Maximum days covered</TableHeader>}
  </>
);

export const ConditionsTableHeader = styled(TableHeader)`
  white-space: nowrap;
`;

export const ConditionsTableCell = styled(TableCell)`
  white-space: normal;
  font-size: 14px;
  color: ${theme.black};

  padding-right: 1rem;
  padding-bottom: 1rem;
`;

export const ConditionsStatusContainer = styled.div<{ isDirty?: boolean, bySystem?: boolean }>`
  padding: 0.2rem 0.8rem;
  border-radius: 2px;
  color: ${props => props.bySystem ? props.theme.white : props.theme.marineBlue};
  background-color: ${props => props.isDirty && props.theme.green};
  background-color: ${props => props.bySystem && props.theme.sanMarino};
`;
