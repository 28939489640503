import { Box } from '@/components/shared/Box';
import { RemainingValidations } from '@/components/shared/RemainingValidations';
import { Tab } from '@/components/shared/Tab';
import styled from 'styled-components';
import { FlexBox } from '@/components/shared/FlexBox';
import { Loader } from '@instech/components';
import { Conditions } from '@/components/shared/TermsDetails/textConditions/Conditions';
import { DaysCoveredTab } from '../PremiumAndRebates';
import { ChangeTabConfirmationModal } from '../ChangeTabConfirmationModal';
import { ConditionsType } from '../../agreementDetails/models';
import { useTabs } from './Hooks/useTabs';
import { tabIsConditions } from './utils';

const ContentContainer = styled.div`
  padding: 2rem;
`;

const ErrorContainer = styled.div`
  margin-top: 2rem;
`;

const SettingsContent = ({ activeTab }: {activeTab: string; }) => {
  if (tabIsConditions(activeTab)) return <Conditions conditionsType={activeTab.toLowerCase() as ConditionsType} />;
  return <DaysCoveredTab />;
};

export const Settings = () => {
  const { tabs, activeTab, handleTabClick, showModal, setShowModal, changeToNextTab } = useTabs();

  if (!activeTab) return <Loader />;

  return (
    <>
      {showModal && (
        <ChangeTabConfirmationModal
          closeModal={() => setShowModal(false)}
          apply={() => changeToNextTab()}
        />
      )}
      <ErrorContainer>
        <RemainingValidations />
      </ErrorContainer>

      <Box header="Settings">
        <FlexBox>
          {tabs.map((tabName, i) => (
            <Tab key={tabName} selected={activeTab === tabName} onClick={() => handleTabClick(i)}>{tabName}</Tab>
          ))}
        </FlexBox>
        <ContentContainer>
          <SettingsContent activeTab={activeTab} />
        </ContentContainer>
      </Box>
    </>
  );
};
