import { FC, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { AgreementType, TextConditionSummary } from '@/models/Agreement';
import { AgreementTiers } from '@/utility/agreementTiers';
import { keysOf } from '@/utility/objectHelpers';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { ConditionsType } from '@/components/pages/agreementDetails/models';
import { useAgreementDataContext } from '@/components/pages/agreementDetails/AgreementDataContext';
import { TextConditionAction } from '@/models/TextConditionAction';
import { NewTerm } from '@/components/shared/ConditionsEditor/ConditionsEditor';
import { ConditionsTable as ConditionsTableSpecific } from '../../../pages/specificTermsDetails/settings/ConditionsTable';
import { ConditionsModal } from './ConditionsModal';
import { ConditionsTable } from './ConditionsTable';

const TableContainer = styled.div`
  margin-bottom: 2rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

interface ConditionsProps {
  conditionsType: ConditionsType;
  agreementType?: AgreementType
}

export const Conditions: FC<ConditionsProps> = ({ conditionsType, agreementType }) => {
  const { viewMode } = useAgreementDetailsContext();
  const { conditions, setConditions } = useAgreementDataContext();
  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);

  if (!conditions) return null;

  const updateConditions = (textCondition: NewTerm, conditionAction: TextConditionAction) => {
    const updatedConditions = [...conditions[conditionsType]];

    const isConditionNewAndRemoved = updatedConditions.some(c => c.termId === textCondition.id && c.action === 'Add') && conditionAction === 'remove';

    if (isConditionNewAndRemoved) {
      const removedCurrent = updatedConditions.filter(c => c.termId !== textCondition.id);
      setConditions(prev => ({ ...prev, [conditionsType]: removedCurrent }));
      return;
    }

    const conditionsUnchanged: TextConditionSummary[] = updatedConditions.filter(c => c.termId !== textCondition.id);

    let conditionChanged: TextConditionSummary[] = [];

    let action: WarWeb.ElementAction;
    if (conditionAction === 'remove') action = 'Delete';
    else action = updatedConditions.some(c => c.termId === textCondition.id) ? 'Edit' : 'Add';
    if (!action) return;

    const basisFields = {
      termId: textCondition.id,
      text: textCondition.text ?? '',
      action,
      isDirty: true,
      mandatoryOptions: [{ option: false }]
    };

    if (agreementType === 'SpecificAgreement') {
      conditionChanged = [{
        ...basisFields,
        includedOptions: [{ option: true }]
      }];
    } else {
      conditionChanged = keysOf(AgreementTiers).filter(tier => tier !== 'Maximum').flatMap(tier => ({
        ...basisFields,
        tier,
        includedOptions: [{ option: false }]
      }));
    }
    const conditionsCombined = [...conditionsUnchanged, ...conditionChanged];
    setConditions(prev => ({ ...prev, ...conditions, [conditionsType]: conditionsCombined }));
  };

  const getIncludedConditions = () => {
    // Minimum tier is global, contains all conditions
    const baseConditions = agreementType === 'SpecificAgreement' ? conditions[conditionsType] : conditions[conditionsType].filter(x => x.tier === 'Minimum');
    const includedConditions: NewTerm[] = baseConditions.filter(x => x.action !== 'Delete').map(c => ({
      id: c.termId,
      text: c.text
    }));

    return includedConditions;
  };

  return (
    <div>
      <Content>
        {conditions && (
          <TableContainer>
            {agreementType === 'SpecificAgreement' ?
              <ConditionsTableSpecific conditionsType={conditionsType} tableData={conditions[conditionsType]} /> :
              <ConditionsTable conditionsType={conditionsType} tableData={conditions[conditionsType]} />}
          </TableContainer>
        )}
        {viewMode !== AgreementViewMode.Inspect && (
          <Container>
            <ButtonGroup>
              <Button
                background={theme.ultraLightGray}
                onClick={() => setShowTermsModal(true)}>
                Add/edit&nbsp;
                {conditionsType}
              </Button>
            </ButtonGroup>

          </Container>
        )}
      </Content>

      <ConditionsModal
        isOpen={showTermsModal}
        closeModal={() => setShowTermsModal(false)}
        conditionsType={conditionsType}
        includedConditions={getIncludedConditions()}
        updateConditions={updateConditions} />
    </div>
  );
};
