import {
  FC, Fragment, useCallback, useEffect, useState
} from 'react';
import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { RichTextView } from '@/components/shared/Form/RichTextForm/RichTextView';
import {
  FilterableTableHeaders,
  Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { TextConditionSummary } from '@/models/Agreement';
import { groupByProp, transpose } from '@/utility/arrayHelpers';
import { keysOf } from '@/utility/objectHelpers';
import { WarWeb } from '@/war';
import { ConditionsType } from '@/components/pages/agreementDetails/models';
import { useAgreementDataContext } from '@/components/pages/agreementDetails/AgreementDataContext';
import styled from 'styled-components';
import { TableColumnHeader } from '@/components/shared/Table/core/Components';
import { SortedHeader } from '@/models/SortOrder';
import { FilterableTableHeadersProvider } from '@/components/pages/defaultTermsConditions/FilterableTableHeadersContext';
import { ConditionsStatusContainer } from '@/components/shared/TermsDetails/SettingsComponents';
import { ActionCell } from './ActionCell';

const WordWrapLayout = styled.div`
  word-break: break-word;
  padding-right: 3em;
`;

const StyledTableCell = styled(TableCell)`
  justify-content: flex-start;
  padding-left: 1rem;
`;

type TransposedConditions = {
  [key in WarWeb.TierType]: TextConditionSummary;
}

// While using data model as view model, we have to handle the scenario when options are empty []
// When we map to request model, we only need termId, tier and option (checked) so we can make a
// simple minimal/placeholder entry
const setOrCreateCheckedState = (options: any[], checked: boolean) => {
  let updatedOptions = [...options || []];
  if (updatedOptions?.some(x => x)) {
    updatedOptions.forEach(x => { x.option = checked; });
  } else {
    updatedOptions = [{
      option: checked,
    }];
  }
  return updatedOptions;
};

const tableHeaders: TableColumnHeader[] = [
  {
    title: 'ID',
    key: 1
  },
  {
    title: 'Description',
    key: 2
  },
  {
    title: 'Mandatory',
    propertyName: 'Minimum',
    key: 3
  },
  {
    title: 'Standard A',
    propertyName: 'A',
    key: 4
  },
  {
    title: 'Standard B',
    propertyName: 'B',
    key: 5
  },
  {
    title: 'Standard C',
    propertyName: 'C',
    key: 6
  }
];

interface ConditionsTableProps {
  conditionsType: ConditionsType;
  tableData: TextConditionSummary[];
}
export const ConditionsTable: FC<ConditionsTableProps> = ({ conditionsType, tableData }) => {
  const { viewMode } = useAgreementDetailsContext();
  const { conditions, setConditions } = useAgreementDataContext();

  const handleInputChange = (checked: boolean, termId: number, tierType: WarWeb.TierType) => {
    const updatedConditions = [...conditions[conditionsType]];
    const condition = updatedConditions.filter(x => x.tier === tierType)?.find(a => a.termId === termId);
    if (!condition) return;
    if (tierType === 'Minimum') {
      // mutate condition, to set mandatory on all areas/agreements
      setOrCreateCheckedState(condition.mandatoryOptions, checked);

      // mutate updatedConditions, to set included on all tiers (A-B-C)
      const otherTiers = updatedConditions.filter(x => x.tier !== tierType)?.filter(a => a.termId === termId);
      otherTiers.filter(a => a.tier !== 'Maximum')?.forEach(x => {
        // ignore if checkbox already is same as (new) mandatory state
        if (!x.includedOptions?.some(y => y) || x.includedOptions?.some(y => y.option !== checked)) {
          // x.includedOptions.forEach(y => { y.option = checked; });
          setOrCreateCheckedState(x.includedOptions, checked);
          x.isDirty = true;
          if (condition.action !== 'Add') x.action = 'Edit';
        }
      });
    }

    setOrCreateCheckedState(condition.includedOptions, checked);

    condition.isDirty = true;
    if (condition.action !== 'Add') condition.action = 'Edit';
    setConditions({ ...conditions, [conditionsType]: updatedConditions });
  };

  const tierSort = (a: WarWeb.TierType, b: WarWeb.TierType) => {
    if (a === 'Minimum') return -1;
    if (b === 'Minimum') return 1;
    return a.localeCompare(b);
  };

  const includedOrMandatory = (a: TextConditionSummary) => {
    const key = a.tier === 'Minimum' ? 'mandatoryOptions' : 'includedOptions';
    return a[key].some(area => area.option);
  };

  const includedOnTierCompare = useCallback((a: TransposedConditions, b: TransposedConditions, sortedHeader: SortedHeader) => {
    const tier = sortedHeader.propertyName;
    const order = sortedHeader.sortOrder === 'asc' ? -1 : 1;
    if (includedOrMandatory(a[tier as WarWeb.TierType]) && !includedOrMandatory(b[tier as WarWeb.TierType])) return order;
    if (!includedOrMandatory(a[tier as WarWeb.TierType]) && includedOrMandatory(b[tier as WarWeb.TierType])) return -order;
    return 0;
  }, []);

  const [groupedConditions, setGroupedConditions] = useState<TransposedConditions[]>();
  const [sortedHeader, setSortedHeader] = useState<SortedHeader>();

  useEffect(() => {
    if (conditions) {
      const applyingTerms = conditions[conditionsType].filter(c => c.tier !== 'Maximum').filter(c => c.action !== 'Delete');
      const grouped = groupByProp(applyingTerms, 'tier');
      const transposed = transpose(grouped);
      setGroupedConditions(sortedHeader ? transposed.sort((a, b) => includedOnTierCompare(a, b, sortedHeader)) : transposed);
    }
  }, [conditions, conditionsType, sortedHeader, includedOnTierCompare]);

  const renderRows = (
    conditionsData?: TransposedConditions[]
  ) => (
    <>
      {conditionsData?.map(c => (
        <TableRow key={c.Minimum.termId}>

          <TableCell top>
            {c.Minimum.termId > 0 ? c.Minimum.termId : ''}
          </TableCell>

          <TableCell top>
            <WordWrapLayout>
              <RichTextView text={c.Minimum.text} />
            </WordWrapLayout>
          </TableCell>

          {keysOf(c).sort(tierSort).map(tier => (
            <Fragment key={tier}>
              <StyledTableCell center>
                <ActionCell onChange={handleInputChange} condition={c[tier]} />
                {viewMode !== AgreementViewMode.Inspect && c[tier].isDirty && (
                  <div>
                    <ConditionsStatusContainer isDirty={c[tier].isDirty} bySystem={c[tier].bySystem}>Edited</ConditionsStatusContainer>
                  </div>
                )}
              </StyledTableCell>

            </Fragment>
          ))}
        </TableRow>
      ))}
    </>
  );

  return (
    <FilterableTableHeadersProvider
      headers={tableHeaders}
      filters={{}} // this probably needs a better fix...
      setFilters={() => { }}
      sortedHeader={sortedHeader}
      setSortedHeader={setSortedHeader}
      isLoading={false}
    >
      <Table layout="0.3fr 5fr 1fr 1fr 1fr 1fr" columns={6} striped>
        <FilterableTableHeaders
          endpoint=""
          delimiters={[]}
        />
        {renderRows(groupedConditions)}
      </Table>
    </FilterableTableHeadersProvider>
  );
};
